<template>
  <app-container icon="edit" colWidth="col-12 col-lg-6" v-if="!isTextLoading">
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="success">
        <p class="mb-4" v-html="texts.content"></p>
        <button class="btn" @click="$router.push({ name: texts.button.route })">
          {{ texts.button.text }}
        </button>
      </div>
    </template>
  </app-container>
</template>

<script>
import Utilities from '@/utils/services/Utilities';
import { types } from '@/config/utils/routes';

export default {
  name: 'ErrorPage',
  data() {
    return {
      texts: {},
      isTextLoading: false,
      page: 'error'
    };
  },
  async created() {
    this.isTextLoading = true;
    const path = this.$route.params.path;
    console.log(path)
    await this.getTexts(path);
    this.isTextLoading = false;
  },
  methods: {
    async getTexts(path) {
      const errorTypes = types.error;
      const error = errorTypes.find((error) => error.path === path);
      this.page = error.type;
      const texts = await Utilities.getPageTexts(this.page);
      this.texts = texts.error;
    },
  },
};
</script>

<style lang="scss">
.success-row {
  margin-top: $sp-3x;
}
</style>
